<template>
<div class="join origin fll--viewport">
  <div v-if="state === states.SIGN_IN || state === states.SIGN_UP" class="join fll--viewport origin">
    <div class="abs--top-left abs--top-right pad--top-lg draggable"></div>
    <div class="abs--center-center flt--clearfix">
      <h1>View a project</h1>
      <form class="join-form ofst--top-lg" @submit.stop.prevent="handleSubmit">
        <h2 class="join-heading" :class="{play}">
          {{heading}}
        </h2>
        <div class="join-inputGroup flx typ--ii ofst--top-md fll--parent" :class="{play}">
          <InputText v-model="email" @ready="handleInputReady" class="flx-fill">Enter email</InputText>
          <button type="submit" class="button--solid rst--button flt--right" :disabled="button_disabled">{{buttonText}}</button>
        </div>
        <p class="join-question typ--center typ--i ofst--top-sm ofst--top-lg" :class="{play}">
          {{question}} <a href="#" @click.stop.prevent="swapSigninSignup">{{linkText}}</a>
        </p>
      </form>
      <!-- <p v-if="error || success" v-html="success || error"></p> -->
    </div>
  </div>
  <div v-if="state === states.ERROR" class="abs--center-center">
    {{error}}
  </div>
  <div v-if="state == states.SUCCESS" class="abs--center-center">
    {{success}}
  </div>
  <Loader v-if="state === states.LOADING" width="4rem" height="4rem" class="abs--center-center" />
</div>
</template>

<script>
import InputText from '@/components/parts/input-text';
import Loader from '@/components/parts/loader'
import {isValidEmail} from '@/lib/validateEmail'
import {minimumTimeout} from '@/lib/minimumTimeout'

export default {
  components: {InputText, Loader},
  data: () => ({
    email: '',
    play: false,
    states: {
      START: 'START',
      LOADING: 'LOADING',
      SENDING_LINK: 'SENDING_LINK',
      SIGN_IN: 'SIGN_IN',
      SIGN_UP: 'SIGN_UP',
      VERIFYING_EMAIL: 'VERIFYING_EMAIL',
      ERROR: 'ERROR',
      SUCCESS: 'SUCCESS'
    },
    errors: {
      'not_signed_in': 'Please sign in',
      'error_with_link': 'Invalid link',
      'error_with_email': 'Error with email, please retry'
    },
    errorKey: null,
    stateKey: 'START',
  }),
  computed: {
    state () {
      return this.stateKey in this.states ? this.states[this.stateKey] : null;
    },
    error () {
      return this.errorKey && this.errorKey in this.errors ? this.errors[this.errorKey] : 'There was an error';
    },
    button_disabled () {
      return isValidEmail(this.email);
    },
    buttonText () {
      return this.state === this.states.SIGN_IN ? 'Sign in' : 'Sign up'
    },
    linkText () {
      return this.state === this.states.SIGN_IN ? 'Sign up' : 'Sign in'
    },
    question () {
      return this.state === this.states.SIGN_IN ? 'Dont\'t have an account?' : 'Already have an account?'
    },
    heading () {
      return this.state === this.states.SIGN_IN ? 'Sign in' : 'Sign up'
    }
  },
  async beforeMount () {
    try {
      this.stateKey = this.states.LOADING;
      this.linkUid = this.$route.params.uid;
      
      if (!this.linkUid) {
        throw {error: 'error_with_link', state: this.states.ERROR}
      }

      await this.$store.dispatch('auth/checkSignedIn').catch(async () => {
        await this.$store.dispatch('auth/confirm', {href: window.location.href}).catch(() => {
          throw {error: 'not_signed_in', state: this.states.SIGN_IN}
        });
        const uid = await this.$store.dispatch('link/getSecureLink', this.linkUid).catch(() => {
          throw {error: 'error_with_link', state: this.states.START}
        });
        this.$router.push({name: 'App', params: {uid}});
      });

      const uid = await this.$store.dispatch('link/getSecureLink', this.linkUid).catch(() => {
        throw {error: 'error_with_link', state: this.states.START}
      });
      this.$router.push({name: 'App', params: {uid}});
    } catch ({error, state}) {
      this.errorKey = error;
      this.stateKey = state;
    }
  },
  methods: {
    swapSigninSignup () {
      this.play = true;

      setTimeout(() => {
        this.stateKey = this.state !== this.states.SIGN_UP ? this.states.SIGN_UP : this.states.SIGN_IN;
      }, 1000)

      setTimeout(() => {
        this.play = false;
        this.focusInput();
      }, 2000)
    },
    handleInputReady({focus}) {
      focus()
      this.focusInput = focus;
    },
    handleSubmit () {
      this.loading = true;
      const timeout = minimumTimeout();
      this.$store.dispatch('auth/signin', {email: this.email, path: '/join/' + this.linkUid}).then(() => {
        timeout().then(() => {
          this.loading = false;
          this.success = `Please check '${this.email}' for a confirmation link`;
          this.stateKey = this.states.SUCCESS
        })
      }).catch(() => {
        this.loading = false;
        this.error = `Sorry, there was an error, please try again.`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes slideInOut {
  0% {
    transform: translate(0,0);
    opacity: 1;
    animation-timing-function: ease;
  }
  // 50% {
  //   transform: translate(0, -10rem);
  //   opacity: 0;
  // }
  50% {
    transform: translate(0, 10rem);
    animation-timing-function: cubic-bezier(.47,1.64,.41,.8);
    opacity: 0;
  }
  100% {
    transform: translate(0,0);
    opacity: 1;
  }
}

.join {
  &-heading {
    animation: slideInOut 2s infinite;
    animation-play-state: paused;
    animation-delay: .15s;
  }

  &-inputGroup {
    animation: slideInOut 2s infinite;
    animation-play-state: paused;
    animation-delay: .1s;
  }
  
  &-question {
    animation: slideInOut 2s infinite;
    animation-play-state: paused;
    animation-delay: 0;
  }
}

.play {
  animation-play-state: running;
}
</style>